<template>
	<div class="home-page">
		<Head :nvlang="lang" @toZh="langchange" @toEn="langchange"/>
		<div class="product-warp">
			<div class="container">
				<div class="nav-beard flex-center">
					<span class="nav-beard-label">当前位置：</span>
					<div class="flex_bd">
						<el-breadcrumb separator="-">
							<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
							<el-breadcrumb-item><a href="/">产品详情</a></el-breadcrumb-item>
						</el-breadcrumb>
					</div>
				</div>
				<div class="product-zs clearfix">
					<div class="product-zs-lt">
						<div class="product-panel">
							<div class="panel-head">
								<div class="panel-title">
									{{info.title}}
								</div>
							</div>
							<div class="panel-body">
								<ul class="product-info">
									<li class="flex" v-for="(item,index) in info.data" :key="index">
										<span class="product-info-label">{{item.label}}</span>
										<span class="product-info-val flex_bd">{{item.value}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="product-zs-rt">
						<div class="product-zs-pic">
							<img :src="info.img" >
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="product-panel js-panel" style="margin-bottom: 130px;">
			<div class="container">
				<div class="panel-head">
					<div class="panel-title">
						{{js.title}}
					</div>
				</div>
				<div class="panel-body">
					<ul class="product-advantage">
						<li v-for="(item,index) in js.adv" :key="index">
							{{item}}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Foot :nvlang="lang"/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				lang:'',
				info:{
					title:'六角形碳化物喷嘴',
					data:[{
						label:'产品规格',
						value:'20*30*50mm'
					},{
						label:'产品材料',
						value:'数据介绍'
					},{
						label:'使用场景',
						value:'数据介绍'
					},{
						label:'安装形式',
						value:'数据介绍'
					},{
						label:'参数5',
						value:'数据介绍'
					}],
					img:require('../assets/images/product1.png')
				},
				js:{
					title:' 产品介绍 ',
					adv:[' 1. 好刚性、低背隙：采用精密研磨齿轮，齿面光滑，齿形精准。',' 2．低噪音：采用精密研磨齿轮，齿面精度好而平滑，使得齿轮运转平顺而噪音小。 采用精选润滑油， 德制精选人工合成润滑油，而非一般减速机使用之油脂润滑，由于流动性佳，使齿轮能在充分油膜保护下运转，降低噪音。',' 3．低齿背隙：如有需要，可接受更低背隙之委制。','4．易安装：减速机之连接板及输入轴孔尺寸采弹性设计，使减速机能与各厂牌的伺服马逹相结合',' 5．长寿命,免保养：精密研磨齿轮，经滚齿及深层的渗碳硬化处理后，再经齿面研磨，使得齿轮刚性大，且齿面光滑耐磨性佳. ']
				}
			};
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			langchange(data){
				this.lang = data;
			},

		}
	}
</script>
